<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="时间范围" name="timeRange">
              <a-range-picker
                format="YYYY-MM-DD "
                :placeholder="['开始日期', '结束日期']"
                @change="onChange"
                @ok="onOk"
                style="width: 100%"
                v-model:value="where.timeRange"
              /> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商:" name="operator">
              <a-select
                v-model:value="where.operator"
                placeholder="请选择所属租赁点"
              >
                <a-select-option value="0">福建厦门三行网点</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点:" name="point">
              <a-select
                v-model:value="where.point"
                placeholder="请选择所属租赁点"
              >
                <a-select-option value="0">福建厦门三行网点</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <div style="position: relative">
                  <AppstoreAddOutlined
                    style="
                      color: #1890ff;
                      font-size: 20px;
                      cursor: pointer;
                      user-select: none;
                    "
                    @click="isShowInfo = !isShowInfo"
                  />
                  <div class="checkbox-wrap" v-if="isShowInfo">
                    <div class="xsj"></div>
                    <a-checkbox-group
                      v-model:value="checkValue"
                      @change="handleChange"
                    >
                      <a-row>
                        <a-checkbox value="0">我的账户</a-checkbox>
                      </a-row>
                      <a-row>
                        <a-checkbox value="1">我的订单</a-checkbox>
                      </a-row>
                      <a-row>
                        <a-checkbox value="2">电池租赁情况</a-checkbox>
                      </a-row>
                    </a-checkbox-group>
                  </div>
                </div>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
    </a-card>
    <div class="my-account" v-if="checkValue.indexOf('0') !== -1">
      <div class="account-title">
        <i class="iconfont icon-zhanghu"></i>
        <span>我的账户</span>
      </div>
      <a-row :gutter="16" type="flex" justify="space-around">
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <i class="iconfont icon-shouruguanli"></i>
          </div>
          <div class="money-item">
            <span>累计金额(元)</span>
            <span v-if="!isShowMoney">******</span>
            <span v-else>145000.32</span>
          </div>
          <div class="show-info">
            <i
              class="iconfont icon-bukejian"
              v-if="!isShowMoney"
              @click="isShowMoney = true"
            ></i>
            <i
              class="iconfont icon-kejian"
              v-else
              @click="isShowMoney = false"
            ></i>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <i class="iconfont icon-jine"></i>
          </div>
          <div class="money-item">
            <span>租金金额(元)</span>
            <span v-if="!isShowMoney">******</span>
            <span v-else>14500.32</span>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <i class="iconfont icon-jifen"></i>
          </div>
          <div class="money-item">
            <span>押金金额(元)</span>
            <span v-if="!isShowMoney">******</span>
            <span v-else>14500.32</span>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <i class="iconfont icon-anquan"></i>
          </div>
          <div class="money-item">
            <span>丢失保障服务费金额(元)</span>
            <span v-if="!isShowMoney">******</span>
            <span v-else>14500.32</span>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="my-account" v-if="checkValue.indexOf('1') !== -1">
      <div class="account-title">
        <i class="iconfont icon-wodedingdan"></i>
        <span>我的订单</span>
      </div>
      <a-row :gutter="16" type="flex" justify="space-around">
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <!-- <i class="iconfont icon-shouruguanli"></i> -->
            <img src="/static/1.png" alt="" style="width: 58px; height: 58px" />
          </div>
          <div class="money-item">
            <span>总订单</span>
            <count-to
              :start-val="0"
              :end-val="55"
              :duration="3000"
            ></count-to>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <img src="/static/2.png" alt="" style="width: 58px; height: 58px" />
          </div>
          <div class="money-item">
            <span>新增订单</span>
            <count-to :start-val="0" :end-val="35" :duration="3000"></count-to>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <img src="/static/3.png" alt="" style="width: 58px; height: 58px" />
          </div>
          <div class="money-item">
            <span>续费订单</span>
            <count-to :start-val="0" :end-val="50" :duration="3000"></count-to>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap">
          <div class="icon-item">
            <img src="/static/4.png" alt="" style="width: 58px; height: 58px" />
          </div>
          <div class="money-item">
            <span>完结订单</span>
            <count-to :start-val="0" :end-val="5" :duration="3000"></count-to>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="my-account" v-if="checkValue.indexOf('2') !== -1">
      <div class="account-title">
        <i class="iconfont icon-zulin"></i>
        <span>电池租赁情况</span>
      </div>
      <a-row
        :gutter="16"
        type="flex"
        justify="space-around"
        style="margin-bottom: 30px"
      >
        <a-col :span="5" class="col-wrap" :pull='1'>
          <div class="icon-item">
            <img src="/static/5.png" alt="" style="width: 64px; height: 64px" />
          </div>
          <div class="money-item">
            <span>电池总量(台)</span>

            <span>55</span>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap" :pull='3'>
          <div class="icon-item">
            <img src="/static/6.png" alt="" style="width: 64px; height: 64px" />
          </div>
          <div class="money-item">
            <span>租赁中数量</span>

            <span>31</span>
          </div>
        </a-col>
        <a-col :span="5" class="col-wrap" :pull='5'>
          <div class="icon-item">
            <img src="/static/7.png" alt="" style="width: 64px; height: 64px" />
          </div>
          <div class="money-item">
            <span>实时出租率</span>

            <span>70.53%</span>
          </div>
        </a-col>
      </a-row>
      <div style="margin: 10px 0">
        <a-space style="margin: 0 20px">
          <a-button type="primary">
            <i class="iconfont icon-daochu" style="margin-right: 5px"></i>
            <span>导出全部数据</span>
          </a-button>
        </a-space>
      </div>
      <ud-pro-table
        ref="table"
        row-key="batteryId"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
      >
        <template #pointName="{ record }">
          <router-link :to="'/point/detail?id=' + record.pointId">
            {{ record.pointName }}
          </router-link>
        </template>
        <template #protocolType="{ record }">
          <span>
            {{ typeProtocol(record.protocolType) }}
          </span>
        </template>
        <template #customTitle>
          <div>
            所属运营商
            <i
              class="iconfont icon-bukejian"
              style="cursor: pointer"
              v-if="!isShowOperator"
              @click="isShowOperator = true"
            ></i>
            <i
              class="iconfont icon-kejian"
              style="cursor: pointer"
              v-else
              @click="isShowOperator = false"
            ></i>
          </div>
        </template>
        <template #operatorCustom="{ record }">
          <div v-if="!isShowOperator">
            {{
              record.operatorName.substring(0, 1) +
              new Array(record.operatorName.length).join('*')
            }}
          </div>
          <div v-else>
            {{ record.operatorName }}
          </div>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此分组吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </div>
    <!-- 编辑弹窗 -->
    <!-- <batteryEdit v-model:visible="showEdit" :data="current" @done="reload" /> -->
    <!-- 详情弹窗 -->
    <!-- <brand-detail v-model:visible="showInfo" :data="current || {}" /> -->
  </div>
</template>

<script>
import * as batteryApi from '@/api/rnt/battery'
// import batteryEdit from './index-edit'
// import brandDetail from './index-detail.vue'
import countTo from '@/components/count-to'
import { createVNode } from 'vue'
import {
  //   PlusOutlined,
  //   DeleteOutlined,
  ExclamationCircleOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'batteryList',
  components: {
    // PlusOutlined,
    // DeleteOutlined,
    AppstoreAddOutlined,
    countTo
    // batteryEdit,
    // brandDetail
  },
  data() {
    return {
      checkValue: ['0', '1', '2'],
      isShowInfo: false,
      isShowMoney: false,
      isShowOperator: false,
      // 表格列配置
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 48,
          align: 'center',
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '电池编号',
          dataIndex: 'batteryCode',
          align: 'center'
        },
        {
          title: '设备编号',
          dataIndex: 'brand',
          align: 'facilityNumber'
        },
        {
          title: '换电次数',
          dataIndex: 'electricNumber',
          align: 'center'
        },
        {
          title: '运营天数',
          dataIndex: 'operatorDay',
          align: 'center'
        },
        {
          title: '循环次数',
          dataIndex: 'cycleNumber',
          align: 'center'
          // slots: { customRender: 'protocolType' }
        },
        {
          dataIndex: 'operatorName',
          align: 'center',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '所属租赁点',
          dataIndex: 'pointName',
          align: 'center',
          slots: { customRender: 'pointName' }
        }
        // {
        //   title: '仓库名称',
        //   dataIndex: 'warehouseName',
        //   align: 'center'
        // },
        // {
        //   title: '操作',
        //   key: 'action',
        //   width: 200,
        //   align: 'center',
        //   slots: { customRender: 'action' }
        // }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      // selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showInfo: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  created() {},
  methods: {
    // 多选择改变监听
    handleChange() {},
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showInfo = true
    },
    typeProtocol(protocolType) {
      if (protocolType === 47) {
        return 'first'
      }
      if (protocolType === 48) {
        return 'second'
      }
    },
    datasource(option, callback) {
      batteryApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      batteryApi
        .deleteById(row.batteryId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.batteryId) }
          batteryApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    }
  }
}
</script>

<style scoped lang='less'>
:deep(.ud-table-tool) {
  display: none;
}
.checkbox-wrap {
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  z-index: 99;
  left: -120px;
  top: 35px;
  background-color: #fff;
  .xsj {
    //   background-color: tomato;
    width: 0;
    height: 0;
    position: absolute;
    left: 120px;
    top: -18px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid rgb(197, 190, 190);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}
.my-account {
  .account-title {
    margin: 10px 25px;
    display: flex;
    align-items: center;
    color: #1890ff;
    .iconfont {
      font-size: 40px;
      font-weight: 500;
      color: #1890ff;
    }
    span {
      font-size: 30px;
      margin-left: 20px;
    }
  }
  .col-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    background-color: #fff;
    height: 162px;
    // width: 390px;
    .icon-item {
      .iconfont {
        font-size: 58px;
        color: #1890ff;
      }
    }
    .money-item {
      width: 200px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: space-around;
      span:nth-child(1) {
        font-size: 16px;
        font-weight: bold;
        color: gray;
      }
      span:nth-child(2) {
        font-size: 42px;
        font-weight: bold;
      }
    }
    .show-info {
      position: absolute;
      right: 10px;
      top: 20px;
      .iconfont {
        font-size: 20px;
        font-weight: 800;
        color: grey;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
